import React from "react";
import Container from "../components/Container";
import Message from "../components/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from "react-device-detect";

const Success = () => (
  <Container>
    <FontAwesomeIcon icon={faSquareCheck} size={isMobile ? "5x" : "6x"} color="#009900" />
    <Message header="Activation completed!" />
  </Container>
);

export default Success;