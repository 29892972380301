import axios from "axios";

let configData = null;

export const getConfig = () =>
  new Promise((resolve, reject) => {
    if (configData) {
      resolve(configData);
    } else {
      let configUrl = "/config";
      if (process.env.NODE_ENV === "development") {
        configUrl = "http://localhost:8081/config";
      }
      // console.log("configUrl", configUrl);
      axios
        .get(configUrl)
        .then((newConfig) => {
          // console.log("configUrl newConfig", newConfig);
          configData = newConfig.data;
          resolve(configData);
        })
        .catch((error) => {
          console.log("configUrl error", error);
          reject(error);
        });
    }
  });

export const COOKIE_PREFIX = () =>
  getConfig()
    .then((config) => Promise.resolve(config.cookiePrefix))
    .catch((error) => Promise.reject(error));

export const API_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.apiUrl}client/`))
    .catch((error) => Promise.reject(error));

export const BASE_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(config.baseUrl))
    .catch((error) => Promise.reject(error));