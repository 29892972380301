import React from "react";
import Container from "../components/Container";
import Message from "../components/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from "react-device-detect";

const NoAccess = () => (
  <Container>
    <FontAwesomeIcon icon={faExclamationCircle} size={isMobile ? "5x" : "6x"} color="#c33" />
    <Message header={"No access"} content="You don't have the appropriate administration privileges." />
  </Container>
);

export default NoAccess;
