import axios from "axios";
import Cookies from "universal-cookie";
import { isIE } from "react-device-detect";
import { Buffer } from "buffer";
import { API_URL, COOKIE_PREFIX } from "../env";

const cookies = new Cookies();

// Simple config of Axios - Setting authentication by token
export async function getAxiosConfig(queryParams = {}) {

  const cookiePrefix = await COOKIE_PREFIX();

  const useRefreshToken = cookies.get(`${cookiePrefix}_use_refresh_token`);
  const expiredAccessToken = hasTokenExpired(cookies.get(`${cookiePrefix}_access_token`));
  if (expiredAccessToken && useRefreshToken) {
    await updateAccessToken(cookiePrefix);
  }

  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const reqHeaders = {};
  reqHeaders.Authorization = `Bearer ${accessToken}`;
  // restrict caching if browser is IE
  if (isIE) {
    reqHeaders.Pragma = 'no-cache';
  }

  const newQueryParams = queryParams;
  if (newQueryParams.search === '') {
    newQueryParams.search = undefined; // eslint-disable-line no-param-reassign
  }

	return { headers: reqHeaders, params: newQueryParams };
}

export function hasTokenExpired(token) {
  if (!token) {
    // console.log("no token");
    return true;
  }

  try {
    const splitToken = token.split(".")[1];
    var buffer = Buffer.from(splitToken, "base64");
    var decodedString = buffer.toString();
    const decodedToken = JSON.parse(decodedString);
    const now = new Date();
    const tokenExpiration = new Date(decodedToken.exp * 1000 - 5 * 60 * 1000); // 5 minutes before expiration
    return tokenExpiration < now ;
  }
  catch (e) {
    console.log("catch", e);
    return true;
  }
}