import React from "react";
import { Audio } from "react-loader-spinner";
import style from "./style.module.scss";

const Loader = ({size = "75px", fullScreen = false, inline = false}) => {
  if (inline) {
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Audio color="#ccc" height={size} width={size} /> 
      </div>
    );
  }

  return (
    <>
      <div className={style.loader}>
        <Audio color="#ccc" height={size} width={size} />
      </div>
      {
        fullScreen && (
          <div style={{ width: "100%", height: "100%", backgroundColor: "#F6F6F6" }}></div>
        )
      }
    </>
  );
};

export default Loader;
