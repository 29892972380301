import React, { Component } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import styled from "styled-components";
import Container from "../components/Container";
import Loader from "../components/Loader";
import Message from "../components/Message";
import NoAccess from "./noAccess";
import NotAuthenticated from "./notAuthenticated";
import { API_URL, BASE_URL, COOKIE_PREFIX } from "../env";
import { getParameterByName } from "../helpers";
import * as actions from "../actions";

const cookies = new Cookies();

class Main extends Component {

  constructor(props) {
    // console.log("AuthCodeInput.constructor");
    super(props);
    this.state = {
      initiated: false,
      loading: true,
      hasAccess: false,
      isAuthenticated: false,
      inputArray: ["", "", "", ""],
      errorMessage: null
    }

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onBackgroundClick = this.onBackgroundClick.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onInputChange = this.onInputChange.bind(this);

    const existingCode = getParameterByName("code");
    if (existingCode) {
      cookies.set("bldng_ai_activate_code", existingCode);
    }
  }

  async componentDidMount() {
    // console.log("AuthCodeInput.componentDidMount");

    const baseUrl = await BASE_URL();
    const cookiePrefix = await COOKIE_PREFIX();
    const accessToken = cookies.get(`${cookiePrefix}_access_token`);
    const expiredAccessToken = actions.hasTokenExpired(accessToken);
    const existingCode = cookies.get("bldng_ai_activate_code");

    if (expiredAccessToken) {
      window.location.href = `${baseUrl}login?from=${window.location.href}`;
    }
    else if (existingCode) {
      cookies.remove("bldng_ai_activate_code");
      this.setState({ initiated: true, loading: true, hasAccess: true, isAuthenticated: true, inputArray: existingCode.split("") });
      this.verifyCode(existingCode);
    }
    else {
      this.setState({ initiated: true, loading: false, hasAccess: true, isAuthenticated: true });
    }
  }


  shouldComponentUpdate(nextProps, nextState) {
    // console.log("AuthCodeInput.shouldComponentUpdate", nextProps, nextState);
    return true;
  }

  onBackgroundClick(event) {
    this.setState({ errorMessage: null, inputArray: ["", "", "", ""] }, () => {
      const input = document.querySelectorAll("input")[0];
      input.focus();
    });
  }

  onClick(event, index) {
    this.setState({ errorMessage: null, inputArray: ["", "", "", ""] }, () => {
      const input = document.querySelectorAll("input")[0];
      input.focus();
    });
  }

  onInputChange(event, index) {
    const inputArray = [...this.state.inputArray];
    inputArray[index] = event.target.value.toUpperCase();
    this.setState({ inputArray });

    // Move focus to next input box
    if (event.target.value.length === 1) {

      // Last input box
      if (index === 3) {
        // Submit
        const code = inputArray.join("");
        console.log("code", code);
        this.verifyCode(code);
      }

      const nextInput = document.querySelectorAll("input")[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  onKeyDown(event, index) {
    const inputArray = [...this.state.inputArray];

    // Backspace
    if (event.keyCode === 8) {
      event.preventDefault();

      if (inputArray[index] !== "") {
        inputArray[index] = "";
        this.setState({ inputArray });

        if (index > 0 && index < 3) {
          const prevInput = document.querySelectorAll("input")[index - 1];
          if (prevInput) {
            prevInput.focus();
          }
        }
      }
      else if (index > 0) {
        const prevInput = document.querySelectorAll("input")[index - 1];
        if (prevInput) {
          inputArray[index-1] = "";
          this.setState({ inputArray });
          prevInput.focus();
        }
      }
    }
  }

  verifyCode(authCode) {
    this.setState({ loading: true }, async () => {

      const url = await API_URL();
      axios.post(`${url}screen/activate`, { authCode }, await actions.getAxiosConfig())
        .then((response) => {
          console.log("verifyCode response", response);
          if (response.status === 200) {
            // Redirect to /activated
            window.location.href = "/activated";
          }
          else if (response.status === 401) {
            this.setState({ loading: false, isAuthenticated: false });
          }
          else if (response.status === 403) {
            this.setState({ loading: false, hasAccess: false });
          }
          else {
            // Show error message
            this.setState({ loading: false, errorMessage: "The code is invalid." });
          }
        })
        .catch((error) => {
          console.log("verifyCode error", error);
          this.setState({ loading: false, errorMessage: error.message});
        }
      );
    });
  }

  render() {
    // console.log("AuthCodeInput.state", this.state);

    if (!this.state.initiated) {
      return null;
    }

    if (this.state.loading) {
      return <Loader fullScreen />;
    }

    if (!this.state.hasAccess) {
      return <NoAccess />;
    }

    if (!this.state.isAuthenticated) {
      return <NotAuthenticated />;
    }

    let showFocus = true;
    const inputBoxes = this.state.inputArray.map((input, index) => {
      const hasFocus = showFocus && (input === "");
      if (hasFocus) { showFocus = false; }
      return (
        <InputBox key={index} hasFocus={hasFocus}>
          <Input
            autoFocus={hasFocus}
            value={input}
            inputProps={{ autocapitalize: "characters", textTransform: "uppercase" }}
            onChange={(event) => this.onInputChange(event, index)}
            onClick={(event) => this.onClick(event, index)}
            onKeyDown={(event) => this.onKeyDown(event, index)}
          />
        </InputBox>
      );
    });

    return (
      <Container onClick={this.onBackgroundClick}>
        <Message header="Enter the code" />
        <InputContainer>
          {inputBoxes}
        </InputContainer>
        <Error>{this.state.errorMessage}</Error>
      </Container>
    );
  }
}

export default Main;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
`;

const InputBox = styled.div`
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 80px;
  height: 100px;
  background-color: white;
  box-sizing: border-box;

  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.hasFocus ? "#666" : "#e0e0e0" };

  @media (max-width: 800px) {
    width: 60px;
    height: 75px;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-family: "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 80px;
  font-weight: 500;
  color: #333;
  background-color: transparent;
  caret-color: transparent;
  padding: 0; // Remove iOS default padding

  @media (max-width: 800px) {
    font-size: 40px;
  }
}`;

const Error = styled.div`
  margin-top: 20px;
  color: #a11;
  font-size: 28px;
  height: 28px;

  @media (max-width: 800px) {
    font-size: 22px;
  }
`;