import React from "react";
import styled from "styled-components";
import Container from "../components/Container";
import Message from "../components/Message";

const NotAuthenticated = () => (
  <Container>
    <Message content={"Session has timed out"} />
    <Button onClick={() => window.location.href = "/login"}>Sign in</Button>
  </Container>
);

export default NotAuthenticated;

const Button = styled.button`
  background-color: #1C4D82;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
`;