import styled from "styled-components";

const Container = ({ onClick, children }) => (
  <Wrapper onClick={onClick}>
    <Card>
      {children}
    </Card>
  </Wrapper>
);

export default Container;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
  // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  // background-color: white;

  @media (max-width: 768px) {
    width: 360px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;