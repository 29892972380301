import React from "react";
import styled from "styled-components";

const Message = ({header, content, onClick, onHeaderClick, link = null, inline = false, children}) => {

  let text;
  if (link !== null) {
    const mailhref = `mailto: ${link}`;
    if (content.includes(link)) {
      const contentArray = content.split(link);
      text =<p>{contentArray[0]}<a href={mailhref} style={{ color: "#00458F"}}>{link}</a>{contentArray[1]}</p>
    }
  }
  else {
    text = <p>{content}</p>;
  }

  return (<Container inline={inline} onClick={onClick}>
    <h2 onClick={onHeaderClick}>{header}</h2>
    {text}
  </Container>);
};

export default Message;

const Container = styled.div`
  display: ${props => props.inline ? "inline-block" : "block"};

  h2 {
    font-size: 40px;
    line-height: 1.2;
    margin: 0;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #333;
    text-align: center;
    
  }

  p {
    font-size: 28px;
    line-height: 1.2;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #333;
    text-align: center;
  }

  @media (max-width: 800px) {

    h2 {
      font-size: 40px;
    }

    p {
      font-size: 22px;
    }
  }
`;