import React from "react";
import { Switch, Route } from "react-router-dom";
import Main from "./main";
import Success from "./success";
import NotFound from "./notFound";
import style from "./style.module.scss";

const App = () => (
  <div className={style.app}>
    <div className={style.content}>
      <Switch>
        <Route path="/activated" children={<Success />} />
        <Route exact path="/" children={<Main />} />
        <Route children={<NotFound />} />
      </Switch>
    </div>
  </div>
);

export default App;