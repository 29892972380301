import React from "react";
import Container from "../components/Container";
import Message from "../components/Message";

const NotFound = () => (
  <Container>
    <Message header="404" content="Sorry, the page you are looking for could not be found" />
  </Container>
);

export default NotFound;